import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './css/index.css';
import Main from "./pages/main";
import Userpage from "./pages/userpage";
import Home from "./pages/home";
import Add from "./pages/add";
import Buy from "./pages/buy";
import Kinotel from "./pages/kinotel";
import ORANGE from "./pages/orange";
import CRMA from "./pages/crm";
import Menucrm from "./pages/menucrm";
import EditAdd from "./pages/editadd";
import { createRoot } from 'react-dom/client';
import Global from "./pages/global";
import { logEvent } from 'firebase/analytics';
import { analytics } from './firebase';

const App = () => {
    const [products, setProducts] = useState([]);
    useEffect(() => {
        if( localStorage.getItem("activeItem") === ""){
            localStorage.setItem("activeItem", "2");
        }
        logEvent(analytics, 'page_view');
    }, []);
    localStorage.setItem("activeItem", "2");


    return (
        <Router>

            <Routes>
                <Route path="/" element={<Main />} />

                <Route path="/edit-add/:companyId/:productId" element={<EditAdd />} />
                <Route path="/copacabana" element={<Main />} />
                <Route path="/aromiitaliani" element={<Main />} />
                <Route path="/symphosium" element={<Main />} />
                <Route path="/nadimi" element={<Main />} />
                <Route path="/poshnosh" element={<Main />} />

                <Route path="/zcrm" element={<Menucrm />} />
                <Route path="/g" element={<Global />} />


                <Route path="/userpage" element={<Userpage />} />
                <Route path="/kinotel" element={<Kinotel />} />
                <Route path="/orange" element={<ORANGE />} />
                <Route path="/crm" element={<CRMA />} />
                <Route path="/home" element={<Home />} />
                <Route path="/addd" element={<Add />} />
                <Route path="/buy" element={<Buy />} />

            </Routes>

        </Router>
    );
};

createRoot(document.getElementById('root')).render(<App />);
