import React, {useState, useEffect} from "react";
import {fs} from "../firebase"; // Ensure Firestore is properly imported
import {
    collection,
    getDocs,
    doc,
    getDoc,
    updateDoc,
    arrayUnion,
} from "firebase/firestore";
import "../css/chat.css";
import {useTranslation} from "react-i18next";
import { parse, format } from 'date-fns';

function CRMA({handleXBarItemChange}) {
    const {t} = useTranslation();
    const [messageText, setMessageText] = useState("");
    const [chatStatus, setChatStatus] = useState("orders");
    const [chats, setChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [chatMessages, setChatMessages] = useState([]);
    const [showMessages, setShowMessages] = useState({});
    const [selectedChatContent, setSelectedChatContent] = useState({});

    const userPost = localStorage.getItem("USER_POST");

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (chatStatus === "orders") {
                const fetchChats = async () => {
                    try {
                        const chatsSnapshot = await getDocs(
                            collection(fs, "ORDERS", userPost, "BOOKINGS")
                        );
                        const chatsList = [];
                        for (const chatDoc of chatsSnapshot.docs) {
                            const chatData = chatDoc.data();
                            const chatMessages = chatData.messages || [];
                            const unreadCount = chatMessages.filter(message => message.user === "admin" && !message.viewed).length;
                            const chatItem = {
                                id: chatDoc.id,
                                mail: chatData.mail,
                                number: chatData.number,
                                name: chatData.name,
                                dates: chatData.dates,
                                productName: chatData.orderName,
                                data: chatData,
                                unreadCount: unreadCount
                            };
                            chatsList.push(chatItem);
                        }
                        setChats(chatsList);
                    } catch (error) {
                        console.error("Error fetching chats:", error);
                    }
                };
                fetchChats();
            }
        }, 1000);


        return () => clearInterval(intervalId);
    }, [userPost, chatStatus]);

    const [modalChat, setModalChat] = useState(false);

    const showMSG = async () => {
        setModalChat(!modalChat);
    };

    const formatDate = (date) => {
        const milliseconds = date.seconds * 1000 + date.nanoseconds / 1e6;
        const jsDate = new Date(milliseconds);

        const options = { year: 'numeric', month: 'long', day: 'numeric' };

        return jsDate.toLocaleDateString('ru-RU', options);
    };
    localStorage.setItem("category", "");
    return (
        <div className="chat-container">
            {!modalChat && chatStatus === "orders" && (
                <div className={"orders_list"}>
                    <div className={"orders_list_container"}>
                        {chats.map((chat) => (
                            <div className={"orders_list_item"} key={chat.id}>
                                <div className={`chat-item ${selectedChat === chat.id ? "active" : ""}`} onClick={() => setSelectedChat(chat.id)}>
                                    {chat.unreadCount !== undefined && chat.unreadCount !== 0 && (
                                        <div className={"unread_count"}>
                                            {chat.unreadCount}
                                        </div>
                                    )}
                                    <div className={"orders_list_item_id"}>
                                        {chat.name}
                                    </div>
                                    <div className={"orders_list_item_id"}>
                                        {chat.mail}
                                    </div>
                                    <div className={"orders_list_item_id"}>
                                        {chat.number}
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default CRMA;
