import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import i18n from '../i18n';
import '../css/index.css';
import '../css/bar.css';
import En from "../img/en.svg";
import Ge from "../img/ge.svg";
import Uk from "../img/uk.svg";
import Ru from "../img/ru.svg";
import Ar from "../img/ar.svg";
import Tr from "../img/tr.svg";

function Bar({handleXBarItemChange}) {
    const {t} = useTranslation();


    const [selectedLanguage, setSelectedLanguage] = useState(() => {
        const savedLanguage = localStorage.getItem("selectedLanguage");
        return savedLanguage || 'en';
    });


    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setSelectedLanguage(lng);
        localStorage.setItem("selectedLanguage", lng);
    };


    useEffect(() => {
        const savedLanguage = localStorage.getItem("selectedLanguage");
        setSelectedLanguage(savedLanguage || 'en');
    }, []);

    return (
        <div className="Bar_container">
            <div className="Bar">
                <div className="Icons">
                    <Link className="logo" to={"/"} onClick={() => {
                        localStorage.setItem("activeItem", "2");
                        handleXBarItemChange(2);
                    }}>Europe Square</Link>
                    <div className="swicher"></div>



                    <div className="lang">
                        <img
                            className="flag"
                            src={
                                selectedLanguage === 'en' ? En :
                                    selectedLanguage === 'ge' ? Ge :
                                        selectedLanguage === 'it' ? Uk :
                                            selectedLanguage === 'ar' ? Ar :
                                                selectedLanguage === 'tr' ? Tr :
                                                    selectedLanguage === 'ru' ? Ru : En
                            }
                            alt="Flag"
                        />
                        <select
                            className="language-dropdown"
                            value={selectedLanguage}
                            onChange={(e) => changeLanguage(e.target.value)}
                        >
                            <option value="en">En</option>
                            <option value="ge">ქა</option>
                            <option value="it">It</option>
                            <option value="ar">Ar</option>
                            <option value="tr">Tr</option>
                            <option value="ru">Ру</option>
                        </select>
                    </div>

                    <div className="IconsR">
                        <div className="Icon">
                            <div className="Burger" onClick={({target}) => target.classList.toggle('active')}>
                                <div className="burgerICO">
                                    <svg id="line" className="ham hamRotate ham1 null" viewBox="0 0 100 100" width="57">
                                        <path className="line top path"
                                              d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"></path>
                                        <path className="line middle path" d="m 30,50 h 40"></path>
                                        <path className="line bottom path"
                                              d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Bar;
